import React from "react";
import { graphql, Link } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";

class NotFoundPage extends React.Component {
  render() {
    const { data } = this.props;
    const siteTitle = data.site.siteMetadata.title;

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <div style={{ textAlign: "center", padding: "10%" }}>
          <SEO title="Polityka prywatności" />
          <h1
            className="title"
            style={{ fontSize: "2em", fontWeight: "bold", color: "#00ff00" }}
          >
            Polityka prywatności
          </h1>
          <h2 className="subtitle">Regulamin korzystania z serwisu</h2>
        </div>
        <div style={{ textAlign: "left", padding: "0% 10%" }}>
          <h2 className="subtitle"> Kim jesteśmy? </h2>

          <p>
            Jesteśmy firmą świadczącą usługi z zakresu inżynieri środowiska -{" "}
            <b>Rexer</b>, adres naszej strony internetowej to:{" "}
            <a href="https://rexer.pl">https://rexer.pl</a>{" "}
          </p>
          <br />

          <div id="rodo" style={{ paddingTop: "100px" }}></div>
          <h2 className="subtitle">RODO</h2>
          <p>
            Ochrona Państwa danych osobowych odbywa się zgodnie z wymogami
            powszechnie obowiązujących przepisów prawa w tym: Rozporządzenia
            Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia
            2016r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem
            danych osobowych i w sprawie swobodnego przepływu takich danych oraz
            uchylenia dyrektywy 95/46/WE (ogólne rozporządzenie o ochronie
            danych) tzw. RODO.
          </p>
          <br />
          <h2 className="subtitle">Podstawa przetwarzania danych osobowych:</h2>

          <p>
            Przetwarzanie danych osobowych każdego z Użytkowników dokonującego
            zgłoszenia poprzez formularz, odbywa się w poszanowaniu prawa i
            zgodnie z obowiązującymi przepisami oraz nie narusza praw i wolności
            osoby, której dane dotyczą.
            <b>
              {" "}
              Podstawę przetwarzania danych stanowi zgoda Użytkownika o której
              mówi art. ust 1 lit a oraz art 9 ust 2 lit a RODO.
              <br />
              <br />
              Użytkownik wyraża zgodę na przetwarzanie danych poprzez wskazanie
              ich w formularzu kontaktowym.
            </b>
          </p>
          <br />
          <h2 className="subtitle">Zgoda na przetwarzanie danych osobowych:</h2>

          <p>
            Zgadzam się na przetwarzanie moich danych osobowych tj. imienia i
            adresu mailowego przez firmę Rexer, zarejestrowaną we Wrocławiu przy
            ul. Legnickiej 55F/330, z siedzibą we Wrocławiu przy ul. Legnickiej
            55F/330, w celu wysyłki newslettera oraz informacji handlowych drogą
            elektroniczną. Podanie danych jest dobrowolne. Podstawą
            przetwarzania danych jest moja zgoda. Odbiorcami danych mogą być
            pracownicy administratora danych. Mam prawo wycofania zgody w
            dowolnym momencie. Dane osobowe będą przetwarzane do ew. odwołania
            zgody, a po takim odwołaniu, przez okres przedawnienia roszczeń
            przysługujących administratorowi danych i w stosunku do niego. Mam
            prawo żądania od administratora dostępu do moich danych osobowych,
            ich sprostowania, usunięcia lub ograniczenia przetwarzania , a także
            prawo wniesienia skargi do organu nadzorczego. Kontakt do
            administratora danych osobowych:{" "}
            <a href="mailto:biuro@rexer.pl">biuro@rexer.pl</a>
          </p>

          <br />
          <h2 className="subtitle">
            {" "}
            Jakie dane osobiste zbieramy i dlaczego je zbieramy:{" "}
          </h2>
          <h3 className="subtitle"> Media: </h3>
          <p>
            Zbieramy różnego rodzaju media od użytkowników w celu rozwoju naszej
            działalności oraz łatwiejszej organizacji pracy, również od
            odwiedzających witrynę. Dodając plik na nasz serwer przekazujesz nam
            prawo do jego wykorzystania, usunięcia lub opublikowania. Zarazem
            nie ponosimy odpowiedzialności za treści przesłane nam przez
            anonimowych użytkowników, nieodpowiednie treści będą moderowane oraz
            usuwane.
          </p>
          <br />
          <h3 className="subtitle">Formularze kontaktowe:</h3>
          <p>
            W przypadku formularzy kontaktowych zachowywane przez nas dane to
            twoje imię oraz adres email, numer telefonu oraz odpowiedzi
            udzielone na pytania z formularzy, jak również udostępnione pliki.
          </p>

          <div id="cookies" style={{ paddingTop: "100px" }}>
            {" "}
          </div>
          <h2 className="subtitle"> Ciasteczka (Cookies)</h2>
          <p>
            Jeśli odwiedzisz stronę logowania, utworzymy tymczasowe ciasteczko
            na potrzeby sprawdzenia czy twoja przeglądarka akceptuje ciasteczka.
            To ciasteczko nie zawiera żadnych danych osobistych i zostanie
            wyrzucone, kiedy zamkniesz przeglądarkę. Podczas logowania tworzymy
            dodatkowo kilka ciasteczek potrzebnych do zapisu twoich informacji
            logowania oraz wybranych opcji ekranu. Ciasteczka logowania wygasają
            po dwóch dniach, a opcji ekranu po roku. Jeśli zaznaczysz opcję
            „Pamiętaj mnie”, logowanie wygaśnie po dwóch tygodniach. Jeśli
            wylogujesz się ze swojego konta, ciasteczka logowania zostaną
            usunięte. Jeśli zmodyfikujesz albo opublikujesz artykuł, w twojej
            przeglądarce zostanie zapisane dodatkowe ciasteczko. To ciasteczko
            nie zawiera żadnych danych osobistych, wskazując po prostu na
            identyfikator przed chwilą edytowanego artykułu. Wygasa ono po 1
            dniu. Jeśli zmodyfikujesz albo opublikujesz artykuł, w twojej
            przeglądarce zostanie zapisane dodatkowe ciasteczko. To ciasteczko
            nie zawiera żadnych danych osobistych, wskazując po prostu na
            identyfikator przed chwilą edytowanego artykułu. Wygasa ono po 1
            dniu. Serwis internetowy wykorzystuje pliki cookie, które służą
            identyfikacji Użytkowników przeglądarki podczas korzystania z
            serwisu. Pliki cookies zawierają niewielkie ilości tekstu możliwego
            do odczytania tylko przez stronę, która je wysyła. Dzięki zebranym
            informacjom możemy uzyskać wiedzę o tym, jak często odwiedzasz nasz
            serwis i które jego elementy najbardziej Cię interesują. Pliki
            cookies nie mogą być wykorzystane do infekowania urządzenia wirusami
            lub innym złośliwym oprogramowaniem (malware). Każdy indywidualny
            cookie składa się z czterech podstawowych części: nazwa strony WWW:
            nazwa domeny lub subdomeny, która ustawiła cookie; nazwa cookie:
            cookie ma nazwę, unikatową na stronie, która je ustawiła; termin
            ważności: ważność niektórych plików cookie wygasa po zamknięciu
            przeglądarki (tzw. cookie sesji), inne cookie zostaną automatycznie
            usunięte dopiero po osiągnięciu daty ważności, która została
            ustawiona (tzw. trwałe), wartość: to informacje w pliku cookie,
            których strona WWW używa do „zapamiętania” poprzedniej wizyty. W
            plikach cookie przechowujemy podstawowe informacje o Użytkownikach
            (np. identyfikator) oraz informacje potrzebne do optymalizacji i
            poprawnego wyświetlania treści zawartych na stronach. Informacje te
            służą do: rozpoznawania użytkowników logujących się w serwisie,
            rejestrowania odwiedzin Użytkowników, co pozwala zebrać dane pomocne
            we wprowadzaniu usprawnień w treści i w sposobie nawigacji strony.
            Użytkownicy naszego serwisu w dowolnej chwili zaprzestać
            dostarczania informacji serwisowi poprzez wykasowanie plików cookie
            zapisanych na ich urządzeniach końcowych przez nasz serwis. Aby tego
            dokonać, należy zmienić ustawienia aktualnie używanej przeglądarki
            internetowej. Użytkownicy mają prawo do odmowy zapisywania i
            odczytywania plików cookies na swoim urządzeniu (komputerze,
            telefonie). Aby to zrobić, muszą zaznaczyć odpowiednie ustawienia w
            opcjach przeglądarki internetowej. Skonfigurowanie przeglądarki w
            sposób, który zablokuje instalację plików cookie dla określonych,
            wybranych przez Użytkownika stron internetowych lub dla wszystkich
            stron, może spowodować utratę pewnych funkcjonalności i utrudnić lub
            uniemożliwić pełne wykorzystanie jej możliwości. Zgodnie z wymogami
            powszechnie obowiązującego prawa za potwierdzenie zgody na
            wykorzystanie plików cookie uznaje się skonfigurowanie przeglądarki
            pozwalające na instalację plików cookie na komputerze. Komunikacja
            między komputerem Użytkownika a serwerem obsługującym serwis, w
            trakcie zbierania danych, jest zaszyfrowana. Dodatkowo bazy danych
            są zabezpieczone przed wglądem osób trzecich.
          </p>
          <br />
          <h2 className="subtitle">Osadzone treści z innych witryn:</h2>
          <p>
            Artykuły na tej witrynie mogą zawierać osadzone treści (np. filmy,
            obrazki, artykuły itp.). Osadzone treści z innych witryn zachowują
            się analogicznie do tego, jakby użytkownik odwiedził bezpośrednio
            konkretną witrynę. Witryny mogą zbierać informacje o tobie, w
            ograniczonym stopniu poprzez korzystanie z naszego serwera czyli
            interakcje z poziomu graficznego interfejsu, używać ciasteczek,
            dołączać dodatkowe, zewnętrzne systemy śledzenia i monitorować twoje
            interakcje z osadzonym materiałem, włączając w to śledzenie twoich
            interakcji z osadzonym materiałem jeśli posiadasz konto i jesteś
            zalogowany w tamtej witrynie. Wszystkie działania wykonane w ramach
            osadzonych treści z innych witryn, podlegają politykom prywatności
            dostarczanym przez właścicieli poszczególnych serwisów
            internetowych. Użytkownikowi przysługują wtedy prawa określone
            wcześniej wymienionymi dokumentami, Rexer nie ponosi
            odpowiedzialności za czynności użytkowników wykonane w obrębie
            treści osadzonych z witryn zewnętrznych.
          </p>
          <br />
          <h2 className="subtitle">Analiza statystyk </h2>
          <p>
            Przeprowadzamy analizę statystyk odbiorców naszej witryny przy
            użyciu oprogramowania Google Analytics, przekazuje ono anonimowe
            informacje o użytkownikach naszej witryny, które używamy w celu jej
            usprawniania pod kątem technologicznym jak i treści witryny. Według
            informacji Google zawartych w{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://support.google.com/analytics/answer/3379636?hl=pl"
            >
              Google Ads Data Protection Terms: Service Information
            </a>
            , Google Analytics zbiera następujące typy danych osobistych:
          </p>
          <ul style={{ listStyle: "inside" }}>
            <li>Identyfikatory online włączając pliki cookies</li>
            <li>Adresy IP oraz identyfikatory urządzeń</li>
            <li>Identyfikatory klientów</li>
            <li>
              Używamy funkcji: <b>anonimize IP</b> w celu ochrony danych
              użytkowników zgodnie z wymaganiami w niektórych krajach, takich
              jak np. Niemcy, wprowadzonych na mocy ustawy GDPR.
            </li>
          </ul>
          <br />
          <h2 className="subtitle">Z kim dzielimy się danymi:</h2>
          <p>
            Dane wygenerowane przez oprogramowanie google analytics, zostają
            udostępnione firmie Google. Wszystkie dane witryny udostępniane są
            jedynie członkom zespołu Rexer to jest administratorowi oraz jego
            pracownikom.
          </p>
          <br />
          <h2 className="subtitle">Jak długo przechowujemy twoje dane:</h2>
          <p>
            Dla użytkowników którzy zarejestrowali się na naszej stronie
            internetowej (jeśli tacy są), przechowujemy również informacje
            osobiste wprowadzone w profilu. Każdy użytkownik może dokonać
            wglądu, korekty albo skasować swoje informacje osobiste w dowolnej
            chwili (nie licząc nazwy użytkownika, której nie można zmienić).
            Administratorzy strony również mogą przeglądać i modyfikować te
            informacje.
          </p>
          <br />
          <h2 className="subtitle">Jakie masz prawa do swoich danych:</h2>
          <p>
            Jeśli masz konto użytkownika, możesz zażądać dostarczenia pliku z
            wyeksportowanym kompletem twoich danych osobistych będących w naszym
            posiadaniu, w tym całość tych dostarczonych przez ciebie. Możesz
            również zażądać usunięcia przez nas całości twoich danych osobistych
            w naszym posiadaniu. Nie dotyczy to żadnych danych które jesteśmy
            zobligowani zachować ze względów administracyjnych, prawnych albo
            bezpieczeństwa.
          </p>
          <br />
          <h2 className="subtitle">Każdy Użytkownik ma prawo do:</h2>
          <ul style={{ listStyle: "inside" }}>
            <li>żądania dostępu do swoich danych osobowych,</li>
            <li>sprostowania danych,</li>
            <li>
              żądania uzupełnienia niekompletnych danych osobowych, w tym
              poprzez przedstawienie dodatkowego oświadczenia,
            </li>
            <li>usunięcia danych lub ograniczenia ich przetwarzania,</li>
            <li>
              wniesienia sprzeciwu wobec dalszego przetwarzania danych
              osobowych,
            </li>
            <li>przeniesienia danych osobowych.</li>
            <li>
              wniesienia skargi do organu nadzorczego tj. Prezesa Urzędu Ochrony
              Danych Osobowych.
            </li>
          </ul>
          <br />
          <p>
            Znaki towarowe i produktowe REXER, REXER engineering są własnością
            REXER sp. z o.o. sp. k. i są chronione prawem autorskim. Pozostałe
            logotypy, znaki graficzne i inne elementy nie będące własnością
            REXER sp. z o.o. sp. k. zostały wykorzystane jedynie w celach
            informacyjnych i są własnością ich prawowitych właścicieli. REXER
            sp. z o.o. sp. k. nie odpowiada za zawartość odnośników
            zewnętrznych.{" "}
          </p>

          <br />
          <div style={{ textAlign: "center", padding: "5% 0" }}>
            <Link className="button rexer-button is-size-4" to="/">
              Wróć na home
            </Link>
          </div>
        </div>
      </Layout>
    );
  }
}

export default NotFoundPage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
